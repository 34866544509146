import { ChainId } from '@pancakeswap/chains'
import { useTranslation } from '@pancakeswap/localization'
import { useToast } from '@pancakeswap/uikit'
import { authenticatedBitcoin } from 'config/constants/cross-swap'
import { useAuthBitcoinContext, useBitcoinAccount, useBitcoinLogout } from 'contexts/ZetaContext/hook'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo } from 'react'
import {
  WalletBitcoinConnectorEnums,
  useWalletBitcoinProviderByWallet,
  useWalletBitcoinProviders,
} from './WalletBitcoinProvider/useWalletBitcoinProviders'
import { useActiveChainId } from './useActiveChainId'
import useActiveWeb3React from './useActiveWeb3React'

export const useAuthBitcoin = () => {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()

  const walletBitcoinProvider = useWalletBitcoinProviders()

  const { updateBitcoinAccount } = useAuthBitcoinContext()
  const logoutBitcoin = useBitcoinLogout()
  const { toastError } = useToast()

  const login = useCallback(
    async (wallet: WalletBitcoinConnectorEnums, chainId: ChainId) => {
      const provider = walletBitcoinProvider[wallet]
      try {
        if (provider && account) {
          await provider.connect()
          await provider.changeNetwork(chainId)

          const btc = await provider.getAccount()

          localStorage.setItem(authenticatedBitcoin, wallet)
          updateBitcoinAccount(btc.address)

          return btc
        }

        toastError(t('Bitcoin Connect'), t("You don't have bitcoin wallet"))
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    [account, t, toastError, updateBitcoinAccount, walletBitcoinProvider],
  )

  const logout = useCallback(async () => {
    logoutBitcoin()
    localStorage.removeItem(authenticatedBitcoin)
  }, [logoutBitcoin])

  const switchNetwork = useCallback(
    async (chainId: ChainId) => {
      const wallet = localStorage.getItem(authenticatedBitcoin)
      await login(wallet as WalletBitcoinConnectorEnums, chainId)
    },
    [login],
  )

  return useMemo(
    () => ({ login, logout, switchNetwork, isLoaded: Boolean(account) }),
    [login, logout, switchNetwork, account],
  )
}

export const useAutoConnectBitcoinWallet = () => {
  const { chainId } = useActiveChainId()
  const { isConnected } = useActiveWeb3React()

  const bitcoinAccount = useBitcoinAccount()
  const provider = useWalletBitcoinProviderByWallet()
  const logoutBitcoin = useBitcoinLogout()

  const { login } = useAuthBitcoin()

  useEffect(() => {
    const wallet = localStorage.getItem(authenticatedBitcoin) as WalletBitcoinConnectorEnums

    if (provider && wallet && isConnected && !bitcoinAccount) {
      debounce(() => wallet && login(wallet, chainId), 1000)()
    } else if (!provider && bitcoinAccount) {
      logoutBitcoin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login, provider, isConnected])
}
