import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Failed: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.5 0.25C5.29 0.25 0.25 5.29 0.25 11.5C0.25 17.71 5.29 22.75 11.5 22.75C17.71 22.75 22.75 17.71 22.75 11.5C22.75 5.29 17.71 0.25 11.5 0.25ZM2.5 11.5C2.5 6.5275 6.5275 2.5 11.5 2.5C13.5813 2.5 15.4937 3.20875 17.0125 4.40125L4.40125 17.0125C3.20875 15.4937 2.5 13.5813 2.5 11.5ZM11.5 20.5C9.41875 20.5 7.50625 19.7913 5.9875 18.5987L18.5987 5.9875C19.7913 7.50625 20.5 9.41875 20.5 11.5C20.5 16.4725 16.4725 20.5 11.5 20.5Z"
          fill="#F80101"
        />
      </svg>
    </Svg>
  );
};

export default Failed;
