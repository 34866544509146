import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../index'
import { addStateTransactions, removeStateTransactions, updateStateTransaction } from './actions'
import { CrossChainStatus, CrossChainTransaction } from './types'

export const useCrossChainTransaction = (): CrossChainTransaction[] => {
  const { account } = useActiveWeb3React()
  const transactions = useSelector((state: any) => state.crossSwapTransactions.transactions)

  return useMemo(() => {
    try {
      if (!account || !transactions) return []
      const txn = transactions[account] || []
      return JSON.parse(JSON.stringify([...txn]))
    } catch (e) {
      return []
    }
  }, [transactions, account])
}
export const useCrossChainTransactionPendings = (): CrossChainTransaction[] => {
  const transactions = useCrossChainTransaction()

  return useMemo(
    () =>
      transactions.filter(
        (txn) =>
          txn?.status &&
          ![CrossChainStatus.OutboundMined, CrossChainStatus.Aborted, CrossChainStatus.Reverted].includes(txn?.status),
      ),
    [transactions],
  )
}

export const useAddTransactionCrossSwap = () => {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  const handleAddTransaction = useCallback(
    (transaction: CrossChainTransaction) => {
      if (account) {
        dispatch(addStateTransactions({ account, txn: transaction }))
      }
    },
    [account, dispatch],
  )
  return useMemo(() => handleAddTransaction, [handleAddTransaction])
}
export const useUpdateTransactionCrossSwap = () => {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  const handleUpdateTransaction = useCallback(
    (transaction: CrossChainTransaction) => {
      if (account) {
        dispatch(updateStateTransaction({ account, txn: transaction }))
      }
    },
    [account, dispatch],
  )
  return useMemo(() => handleUpdateTransaction, [handleUpdateTransaction])
}
export const useRemoveTxn = () => {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  const handleUpdateTransaction = useCallback(
    (transaction: CrossChainTransaction) => {
      if (account) {
        dispatch(removeStateTransactions({ account, txn: transaction }))
      }
    },
    [account, dispatch],
  )
  return useMemo(() => handleUpdateTransaction, [handleUpdateTransaction])
}
