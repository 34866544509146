// eslint-disable-next-line camelcase
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { config } from 'utils/wagmi'
import { usePublicClient, useWatchBlockNumber } from 'wagmi'

export const REFRESH_BLOCK_INTERVAL = 6000

export const usePollBlockNumber = () => {
  const queryClient = useQueryClient()
  const { chainId } = useActiveChainId()

  useWatchBlockNumber({
    config,
    chainId,
    emitOnBegin: true,
    emitMissed: true,
    syncConnectedChain: true,
    pollingInterval: REFRESH_BLOCK_INTERVAL,
    onBlockNumber: (data) => {
      queryClient.setQueryData(['blockNumber', chainId], Number(data))
      queryClient.setQueryData(['initialBlockTimestamp', chainId], Number(data))
      queryClient.setQueryData(['blockNumber', chainId], Number(data))
    },
  })
}

export const useCurrentBlock = (): number => {
  const { chainId } = useActiveChainId()
  const { data: currentBlock = 0 } = useQuery<number>(['blockNumber', chainId], {
    enabled: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
  return Number(currentBlock)
}

export const useChainCurrentBlock = (chainId: number): number => {
  const { chainId: activeChainId } = useActiveChainId()
  const provider = usePublicClient({ chainId })

  const { data: currentBlock = 0 } = useQuery(
    activeChainId === chainId ? ['blockNumber', chainId] : ['chainBlockNumber', chainId],
    async () => {
      const blockNumber = await provider?.getBlockNumber()
      return Number(blockNumber)
    },
    {
      enabled: activeChainId !== chainId,
      ...(activeChainId !== chainId && { refetchInterval: REFRESH_BLOCK_INTERVAL }),
    },
  )
  return currentBlock
}

export const useInitialBlock = (): number => {
  const { chainId } = useActiveChainId()
  const { data: initialBlock = 0 } = useQuery<number>(['initialBlockNumber', chainId], {
    enabled: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
  return Number(initialBlock)
}

export const useInitialBlockTimestamp = (): number => {
  const { chainId } = useActiveChainId()
  const { data: initialBlockTimestamp = 0 } = useQuery<number>(['initialBlockTimestamp', chainId], {
    enabled: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
  return Number(initialBlockTimestamp)
}
