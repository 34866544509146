import { ChainId } from '@pancakeswap/chains'
import { ABS, CAKE } from '@pancakeswap/tokens'
import { BIG_ZERO } from '@pancakeswap/utils/bigNumber'
import BigNumber from 'bignumber.js'
import { DEFAULT_CHAINID } from 'config/chains'
import { useMemo } from 'react'
import { REFRESH_BLOCK_INTERVAL } from 'state/block/hooks'
import { getVeCakeAddress } from 'utils/addressHelpers'
import { Address, erc20Abi } from 'viem'
import { useAccount, useBalance, useReadContract } from 'wagmi'
import { useActiveChainId } from './useActiveChainId'

const useTokenBalance = (tokenAddress: Address, forceBSC?: boolean) => {
  return useTokenBalanceByChain(tokenAddress, forceBSC ? DEFAULT_CHAINID : undefined)
}

export const useTokenBalanceByChain = (tokenAddress: Address, chainIdOverride?: ChainId) => {
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()

  const { data, status, ...rest } = useReadContract({
    chainId: chainIdOverride || chainId,
    abi: erc20Abi,
    address: tokenAddress,
    functionName: 'balanceOf',
    args: [account || '0x'],

    query: {
      enabled: !!account,
      cacheTime: REFRESH_BLOCK_INTERVAL,
      refetchInterval: REFRESH_BLOCK_INTERVAL,
    },
  })

  return {
    ...rest,
    fetchStatus: status,
    balance: useMemo(() => (typeof data !== 'undefined' ? new BigNumber(data.toString()) : BIG_ZERO), [data]),
  }
}

export const useGetBnbBalance = () => {
  const { address: account } = useAccount()
  const { status, refetch, data } = useBalance({
    chainId: ChainId.BSC,
    address: account,
    query: {
      enabled: !!account,
      cacheTime: REFRESH_BLOCK_INTERVAL,
      refetchInterval: REFRESH_BLOCK_INTERVAL,
    },
  })

  return { balance: data?.value ? BigInt(data.value) : 0n, fetchStatus: status, refresh: refetch }
}

export const useBSCCakeBalance = () => {
  const { balance, fetchStatus } = useTokenBalance(CAKE[ChainId.BSC]?.address, true)

  return { balance: BigInt(balance.toString()), fetchStatus }
}

export const useZetaAbsBalance = () => {
  const { balance, isFetched } = useTokenBalance(ABS[DEFAULT_CHAINID]?.address, true)

  return { currency: ABS[DEFAULT_CHAINID], balance, isFetched }
}

// veCake only deploy on bsc/bscTestnet
export const useVeCakeBalance = () => {
  const { chainId } = useActiveChainId()
  const { balance, fetchStatus } = useTokenBalance(getVeCakeAddress(chainId))

  return { balance, fetchStatus }
}

export default useTokenBalance
