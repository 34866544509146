import { createContext, useCallback, useMemo, useState } from 'react'

export type ZetaContextProps = {
  bitcoinAccount: string
  updateBitcoinAccount: (address: string) => void
  clearContext: () => void
}

export const ZetaContext = createContext<ZetaContextProps>({
  bitcoinAccount: '',
  updateBitcoinAccount: () => {},
  clearContext: () => {},
})

const ZetaContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [bitcoinAccount, setBitcoinAccount] = useState<string>('')

  const handleSetBitcoinAddress = useCallback((address: string) => setBitcoinAccount(address), [setBitcoinAccount])

  const clearContext = useCallback(() => {
    setBitcoinAccount('')
  }, [])

  const value = useMemo(
    () => ({
      bitcoinAccount,
      clearContext,
      updateBitcoinAccount: handleSetBitcoinAddress,
    }),
    [bitcoinAccount, clearContext, handleSetBitcoinAddress],
  )
  return <ZetaContext.Provider value={value}>{children}</ZetaContext.Provider>
}
export default ZetaContextProvider
