import { ChainId } from '@pancakeswap/chains'
import { MethodCrossSwap } from 'config/constants/cross-swap'

// - Inbound: create tx, waiting confirm at explorer
// - PendingInbound: some observer sees inbound tx
// - PendingOutbound: super majority observer see inbound tx
// - OutboundMined: the corresponding outbound tx is mined
// - PendingRevert: outbound cannot succeed; should revert inbound
// - Reverted: inbound reverted.
// - Aborted: inbound tx error or invalid paramters and cannot revert; just abortstring
export enum CrossChainStatus {
  Inbound = 'Inbound',
  PendingInbound = 'PendingInbound',
  PendingOutbound = 'PendingOutbound',

  OutboundMined = 'OutboundMined',
  PendingRevert = 'PendingRevert',
  Reverted = 'Reverted',
  Aborted = 'Aborted',
}

type CrossChainTransactionBase = {
  method: MethodCrossSwap
  hash: string

  sourceChainHash?: string
  sourceChainId?: ChainId

  zetachainHashOb?: string
  destinationHash?: string
  destinationChain?: ChainId

  createTime?: number
  updateTime?: number
  status?: CrossChainStatus
}

export type TransactionCrossChainSwap = CrossChainTransactionBase & {
  method: MethodCrossSwap
  amountIn: string
  amountOut: string
  inputChain: ChainId
  outputChain: ChainId
  inputCurrencyId: string
  outputCurrencyId: string
}

export type CrossChainTransaction = TransactionCrossChainSwap
