import { ChainId, isChainBitcoin } from '@pancakeswap/chains'
import { HelpIcon } from '@pancakeswap/uikit'
import { ASSET_CDN } from 'config/constants/endpoints'
import Image from 'next/image'
import { memo } from 'react'
import { isChainSupported } from 'utils/wagmi'

const localLogo = {
  [ChainId.MAINNET]: '/images/chains/7000.png',
  [ChainId.TESTNET]: '/images/chains/7000.png',
  [ChainId.BTC_MAINNET]: '/images/chains/8332.png',
  [ChainId.BTC_TESTNET]: '/images/chains/8332.png',
}

export const getChainLogo = (chainId: ChainId) => localLogo[chainId] ?? `${ASSET_CDN}/web/chains/${chainId}.png`

export const ChainLogo = memo(
  ({ chainId, width = 24, height = 24 }: { chainId: number; width?: number; height?: number }) => {
    if (isChainSupported(chainId) || isChainBitcoin(chainId)) {
      const src = getChainLogo(chainId)
      return (
        <Image
          alt={`chain-${chainId}`}
          style={{ maxHeight: `${height}px` }}
          src={src}
          width={width}
          height={height}
          unoptimized
        />
      )
    }

    return <HelpIcon width={width} height={height} color="primary" />
  },
)
