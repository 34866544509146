import { useTranslation } from '@pancakeswap/localization'
import { Box, Button, Flex, Text } from '@pancakeswap/uikit'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import isEmpty from 'lodash/isEmpty'
import { useAppDispatch } from 'state'
import { clearAllCrossTransactions } from 'state/crossSwapTransactions/actions'
import { useCrossChainTransaction } from 'state/crossSwapTransactions/hooks'
import { styled } from 'styled-components'
import CrossTransactionRow from './CrossTransactionRow'

const TransactionsContainer = styled(Box)`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 6px;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: unset;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: unset;
  }
`

interface WalletTransactionsProps {
  onDismiss: () => void
}

const WalletCrossTransactions: React.FC<React.PropsWithChildren<WalletTransactionsProps>> = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()

  const sortedTransactions = useCrossChainTransaction()
  const hasTransactions = !isEmpty(sortedTransactions)

  const handleClearAll = () => {
    dispatch(clearAllCrossTransactions({ account: account as string }))
  }

  return (
    <Box minHeight="120px">
      <Flex alignItems="center" justifyContent="space-between" mb="16px">
        <Text color="black" fontSize="14px" fontWeight={500}>
          {t('Recent Cross Transactions')}
        </Text>
        <Box minHeight="32px">
          {hasTransactions && (
            <Button scale="sm" onClick={handleClearAll} variant="text" style={{ color: '#000' }} px="0">
              {t('Clear all')}
            </Button>
          )}
        </Box>
      </Flex>
      {hasTransactions ? (
        <TransactionsContainer>
          {sortedTransactions.map((txn) => {
            return <CrossTransactionRow txn={txn} mb="4px" />
          })}
        </TransactionsContainer>
      ) : (
        <Text textAlign="center">{t('No recent cross transactions')}</Text>
      )}
    </Box>
  )
}

export default WalletCrossTransactions
