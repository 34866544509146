import React from "react";
import { Flex } from "../Box";
import { StyledFooter, StyledSocialLinks } from "./styles";

import { Column, LogoIcon, Text } from "..";
import { Link } from "../Link";
import { infoLinks } from "./config";
import { FooterProps } from "./types";

const Footer: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  chainId,
  ...props
}) => {
  return (
    <StyledFooter
      data-theme="dark"
      p={["40px 16px", null, "56px 40px", "56px 40px"]}
      flexDirection={["column", "column", "column", "column", "row"]}
      position="relative"
      {...props}
      gridTemplateColumns={["1fr", "", "1fr 1fr", "", "1fr 1fr 1fr"]}
      justifyContent={["center"]}
      gridGap={40}
    >
      <Flex alignItems="center" width="max-content" position="relative">
        <LogoIcon width="56px" />
        <Column ml="12px">
          <Text color="white" fontSize={["28px"]} fontWeight={600}>
            AbstraDEX
          </Text>
          <Text color="#7F7F7F">© 2023 Powered By AbstraDEX</Text>
        </Column>
      </Flex>

      <StyledSocialLinks mx={["auto", "", "auto"]} width="max-content" />

      <Flex mr={["auto", "", "auto", "", "0"]} ml={["auto", "", "0", "", "auto"]}>
        {infoLinks.map((link) => (
          <Link
            data-theme="dark"
            mx="16px"
            href={link.href}
            target="_blank"
            rel="noreferrer noopener"
            color="white"
            bold={false}
            external
          >
            {link.label}
          </Link>
        ))}
      </Flex>
    </StyledFooter>
  );
};

export default Footer;
