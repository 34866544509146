// import { SUPPORTED_CHAIN_IDS as IFO_SUPPORTED_CHAINS } from '@pancakeswap/ifos'
import { ContextApi } from '@pancakeswap/localization'
import { DropdownMenuItems, MenuItemsType, SwapFillIcon, SwapIcon } from '@pancakeswap/uikit'
// import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) => [
  {
    label: t('Cross Swap'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/cross-swap',
    showItemsOnMobile: false,
  },
  {
    label: t('Cross-Stake'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/cross-stake',
    showItemsOnMobile: false,
    disabled: true,
    isComingSoon: true,
  },
  {
    label: t('NFT MarkePlace'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/nft-marketplace',
    showItemsOnMobile: false,
    disabled: true,
    isComingSoon: true,
  },
  {
    label: t('Cross-Lending'),
    icon: SwapIcon,
    fillIcon: SwapFillIcon,
    href: '/cross-lending',
    showItemsOnMobile: false,
    disabled: true,
    isComingSoon: true,
  },
]

export default config
