/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, memo } from "react";
import styled from "styled-components";
import isTouchDevice from "../../util/isTouchDevice";
import { Box, Flex } from "../Box";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import MenuItem from "../MenuItem/MenuItem";
import { MenuItemsProps } from "./types";

const MenuItems: React.FC<React.PropsWithChildren<MenuItemsProps>> = ({
  items = [],
  activeItem,
  activeSubItem,
  ...props
}) => {
  return (
    <StyledWrapper {...props}>
      {items.map(({ label, items: menuItems = [], href, icon, disabled, isComingSoon }) => {
        const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color;
        const isActive = activeItem === href;
        const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href };
        const Icon = icon;
        return (
          <DropdownMenu
            key={`${label}#${href}`}
            items={menuItems}
            py={1}
            activeItem={activeSubItem}
            isDisabled={disabled}
            mx={["8px", "", "", "", "", "", "16px"]}
          >
            <MenuItem {...linkProps} isActive={isActive} statusColor={statusColor} isDisabled={disabled}>
              {label || (icon && createElement(Icon as any, { color: isActive ? "secondary" : "textSubtle" }))}

              {isComingSoon && (
                <Box
                  position="absolute"
                  left="50%"
                  bottom="-4px"
                  style={{
                    fontSize: "10px",
                    color: "#D1FAE5",
                    whiteSpace: "nowrap",
                    transform: "translateX(-50%)",
                  }}
                >
                  (Coming Soon)
                </Box>
              )}
            </MenuItem>
          </DropdownMenu>
        );
      })}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Flex)`
  max-width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: unset;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: unset;
  }
`;
export default memo(MenuItems);
