import { createAction } from '@reduxjs/toolkit'
import { Address } from 'viem'
import { CrossChainTransaction } from './types'

export const addStateTransactions = createAction<{ account: Address; txn: CrossChainTransaction }>(
  'cross-chain-transactions/addStateTransactions',
)
export const updateStateTransaction = createAction<{ account: Address; txn: CrossChainTransaction }>(
  'cross-chain-transactions/updateStateTransaction',
)

export const removeStateTransactions = createAction<{ account: string; txn: CrossChainTransaction }>(
  'cross-chain-transactions/removeStateTransactions',
)
export const clearAllCrossTransactions = createAction<{ account: string }>(
  'cross-chain-transactions/clearAllCrossTransactions',
)
