import { ChainId, isChainZeta } from '@pancakeswap/chains'
import { Currency } from '@pancakeswap/sdk'
import { TokenAddressMap } from '@pancakeswap/token-lists'
import validateBitcoinAddress from 'bitcoin-address-validation'
import { CHAINS_BTC, DEFAULT_CHAINID } from 'config/chains'
import memoize from 'lodash/memoize'
import { Address, getAddress } from 'viem'
import { bsc } from 'wagmi/chains'
// returns the checksummed address if the address is valid, otherwise returns undefined
export const safeGetAddress = memoize((value: any): Address | undefined => {
  try {
    let value_ = value
    if (typeof value === 'string' && !value.startsWith('0x')) {
      value_ = `0x${value}`
    }
    return getAddress(value_)
  } catch {
    return undefined
  }
})

export const safeGetBitcoinAddress = memoize((value: string): string | undefined => {
  try {
    if (!value || (typeof value === 'string' && value.startsWith('0x'))) {
      return undefined
    }
    return validateBitcoinAddress(value) ? value : undefined
  } catch {
    return undefined
  }
})

export function getBlockExploreLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainIdOverride?: number,
): string {
  const chainId = chainIdOverride || DEFAULT_CHAINID
  const chain = CHAINS_BTC.find((c) => c.id === chainId)
  if (!chain || !chain.blockExplorers) return bsc.blockExplorers.default.url

  const isAccount = type === 'address' && isChainZeta(chainIdOverride)
  const endpoint = isAccount ? 'https://zetachain.blockscout.com' : chain.blockExplorers.default.url

  switch (type) {
    case 'transaction': {
      return `${endpoint}/tx/${data}`
    }
    case 'token': {
      return `${endpoint}/token/${data}`
    }
    case 'block': {
      return `${endpoint}/block/${data}`
    }
    case 'countdown': {
      return `${endpoint}/block/countdown/${data}`
    }
    default: {
      return `${endpoint}/address/${data}`
    }
  }
}

export const getCrossExploreLink = (chainId: ChainId, hash: string, cross?: boolean) => {
  if (!hash) return '#'

  switch (chainId) {
    case ChainId.BTC_MAINNET:
      return `https://live.blockcypher.com/btc/tx/${hash}/`
    case ChainId.BTC_TESTNET:
      return `https://live.blockcypher.com/btc-testnet/tx/${hash}/`

    case ChainId.MAINNET:
      return cross ? `https://zetachain.blockscout.com/tx/${hash}` : `https://explorer.zetachain.com/tx/${hash}`

    case ChainId.TESTNET:
      return cross
        ? `https://zetachain-athens-3.blockscout.com/tx/${hash}`
        : `https://athens.explorer.zetachain.com/tx/${hash}`

    // case ChainId.POLYGON:
    //   return `https://polygonscan.com/tx/${hash}`
    // case ChainId.MUMBAI:
    //   return `https://mumbai.polygonscan.com/tx/${hash}`

    case ChainId.BSC:
      return `https://bscscan.com/tx/${hash}`
    case ChainId.BSC_TESTNET:
      return `https://testnet.bscscan.com/tx/${hash}`

    case ChainId.ETHEREUM:
      return `https://etherscan.io/tx/${hash}`
    case ChainId.GOERLI:
      return `https://goerli.etherscan.io/tx/${hash}`

    default:
      return '#'
  }
}
export function getBlockExploreName(chainIdOverride?: number) {
  const chainId = chainIdOverride || DEFAULT_CHAINID
  const chain = CHAINS_BTC.find((c) => c.id === chainId)

  return chain?.blockExplorers?.default.name || bsc.blockExplorers.default.name
}

export function getBscScanLinkForNft(collectionAddress: string, tokenId: string): string {
  return `${bsc.blockExplorers.default.url}/token/${collectionAddress}?a=${tokenId}`
}

// add 10%
export function calculateGasMargin(value: bigint, margin = 1000n): bigint {
  return (value * (10000n + margin)) / 10000n
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap<ChainId>, currency?: Currency): boolean {
  if (currency?.isNative) return true
  return Boolean(currency?.isToken && defaultTokens[currency.chainId]?.[currency.address])
}
