import { AtomBox, SwapCSS, Text } from "@pancakeswap/uikit";

import { NumericalInput, NumericalInputProps } from "./NumericalInput";

interface CurrencyChainInputPanelPanelProps extends Omit<NumericalInputProps, "onBlur"> {
  onInputBlur?: () => void;
  id: string;
  top?: React.ReactNode;
  bottom?: React.ReactNode;
  middle?: React.ReactNode;
  rightInput?: React.ReactNode;
  showBridgeWarning?: boolean;
}
export function CurrencyChainInputPanel({
  value,
  onUserInput,
  onInputBlur,
  top,
  bottom,
  middle,
  rightInput,
  id,
  disabled,
  error,
  loading,
  showBridgeWarning,
}: CurrencyChainInputPanelPanelProps) {
  return (
    <AtomBox position="relative" id={id} display="grid" gap="4px">
      <AtomBox color="black" display="flex" alignItems="center" justifyContent="space-between">
        {top}
      </AtomBox>
      <AtomBox display="flex" flexDirection="column" flexWrap="nowrap" position="relative" zIndex="1">
        <AtomBox
          className={SwapCSS.inputContainerVariants({
            showBridgeWarning: !!showBridgeWarning,
            error: Boolean(error),
          })}
        >
          <AtomBox
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap="nowrap"
            color="text"
            fontSize="12px"
            lineHeight="16px"
            px="16px"
            py="12px"
            gap="8px"
          >
            {middle}
          </AtomBox>
          <AtomBox
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            flexWrap="nowrap"
            color="text"
            fontSize="12px"
            lineHeight="16px"
            px="16px"
            py="12px"
            gap="8px"
          >
            <NumericalInput
              error={Boolean(error)}
              disabled={disabled}
              loading={loading}
              className="token-amount-input"
              color="white"
              value={value}
              onBlur={onInputBlur}
              onUserInput={(val) => {
                onUserInput(val);
              }}
            />
            {rightInput}
          </AtomBox>
          {bottom}
        </AtomBox>

        {error ? (
          <Text pb="8px" fontSize="12px" color="red">
            {error}
          </Text>
        ) : null}

        {disabled && (
          <AtomBox role="presentation" position="absolute" inset="0px" backgroundColor="black" opacity="0.6" />
        )}
      </AtomBox>
    </AtomBox>
  );
}
