import { Address } from 'viem'

export type RequestProps = {
  method: string
  params: any[]
}

export type BasePropsTransfer = {
  from: Address
  recipient: Address
  memo: string
  feeRate: number

  amount: string
  decimals: number
}
export type BitcoinAccountWallet = {
  address: string
  publicKey: string
}

export const FEE_RATE = 10

export abstract class WalletProvider<T> {
  public provider: T

  constructor(args: any) {
    this.provider = args
  }

  public abstract connect: () => Promise<any>

  public abstract getAccounts: () => Promise<BitcoinAccountWallet[]>

  public abstract getAccount: () => Promise<BitcoinAccountWallet>

  public abstract changeNetwork: (...args) => void

  public abstract getNetwork: () => void

  public abstract request: (data: RequestProps, callback?: (error: any, hash: any) => void) => void

  public abstract transfer: (data: BasePropsTransfer) => Promise<string>
}
