import { ChainId } from '@pancakeswap/chains'
import { getZetaChainByAnotherChain } from 'utils/wagmi'

const endpoints = {
  [ChainId.MAINNET]: {
    'cosmos-http': 'https://zetachain.blockpi.network/lcd/v1/public',
  },
  [ChainId.TESTNET]: {
    'cosmos-http': 'https://zetachain-athens.blockpi.network/lcd/v1/public',
  },
  [ChainId.BTC_MAINNET]: {
    esplora: 'https://blockstream.info/api',
  },
  [ChainId.BTC_TESTNET]: {
    esplora: 'https://blockstream.info/testnet/api',
  },
}

export const getCosmosHttp = (chainId: ChainId) => {
  return endpoints[getZetaChainByAnotherChain(chainId)]['cosmos-http']
}

export const getEsplora = (chainId: ChainId) => {
  return (endpoints[chainId] as any)?.esplora
}
