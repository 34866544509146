import { LIVE, REGISTRATION, SmartContractPhases } from 'config/constants/trading-competition/phases'
import { useTradingCompetitionContractMoD } from 'hooks/useContract'
import { useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'

export const useCompetitionStatus = () => {
  const tradingCompetitionContract = useTradingCompetitionContractMoD()

  const { data: state } = useSWRImmutable('competitionStatus', async () => {
    const competitionStatus = await (tradingCompetitionContract as any)?.read.currentStatus()
    return SmartContractPhases[competitionStatus].state
  })

  return useMemo(() => {
    if (state === REGISTRATION) {
      return 'soon'
    }

    if (state === LIVE) {
      return 'live'
    }

    return null
  }, [state])
}
