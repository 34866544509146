import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | AbstraDex',
  defaultTitle: 'AbstraDex',
  description:
    'A suite of DEX products that provide concentrated liquidity on multi-chain, seamless trading, and a smooth experience for traders.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@AbstraDex',
    site: '@AbstraDex',
  },
  openGraph: {
    title: 'AbstraDEX, the Multichain-Powered Decentralized Exchange Revolution',
    description:
      'A suite of DEX products that provide concentrated liquidity on multi-chain, seamless trading, and a smooth experience for traders.',
    images: [{ url: '/hero.jpg' }],
  },
}
