import { useTranslation } from '@pancakeswap/localization'
import {
  ButtonMenu,
  ButtonMenuItem,
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalTitle,
  ModalWrapper,
  ModalHeader as UIKitModalHeader,
} from '@pancakeswap/uikit'
import { useCallback, useState } from 'react'
import { styled } from 'styled-components'
import { parseEther } from 'viem'
import WalletCrossTransactions from './WalletCrossTransactions'
import WalletInfo from './WalletInfo'
import WalletTransactions from './WalletTransactions'
import WalletWrongNetwork from './WalletWrongNetwork'

export enum WalletView {
  WALLET_INFO,
  TRANSACTIONS,
  CROSS_TRANSACTIONS,
  WRONG_NETWORK,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_NATIVE_BALANCE = parseEther('0.002', 'wei')

const ModalHeader = styled(UIKitModalHeader)`
  background: ${({ theme }) => theme.colors.gradientBubblegum};
`

const Tabs = styled.div`
  padding: 0 24px;
`

const StyledButtonMenuItem = styled(ButtonMenuItem)<{ $active: boolean }>`
  background: ${({ theme, $active }) => ($active ? theme.colors.primary : theme.colors.background)};
  color: #fff;
  font-size: 14px;
  margin: 0 8px;

  &:hover:not(:disabled):not(:active) {
    background: ${({ theme, $active }) => ($active ? theme.colors.primary : theme.colors.background)};
  }
`

const StyledButtonMenu = styled(ButtonMenu)`
  background: transparent;
  border: none;

  ${StyledButtonMenuItem}:first-child {
    margin-left: 0;
  }
  ${StyledButtonMenuItem}:last-child {
    margin-right: 0;
  }
`
interface TabsComponentProps {
  view: WalletView
  handleClick: (newIndex: number) => void
}

const TabsComponent: React.FC<React.PropsWithChildren<TabsComponentProps>> = ({ view, handleClick }) => {
  const { t } = useTranslation()

  return (
    <Tabs>
      <StyledButtonMenu scale="sm" variant="primary" onItemClick={handleClick} activeIndex={view} fullWidth>
        <StyledButtonMenuItem $active={view === 0}>{t('Wallet')}</StyledButtonMenuItem>
        <StyledButtonMenuItem $active={view === 1}>{t('Transactions')}</StyledButtonMenuItem>
        <StyledButtonMenuItem $active={view === 2}>{t('Cross Txn')}</StyledButtonMenuItem>
      </StyledButtonMenu>
    </Tabs>
  )
}

const WalletModal: React.FC<React.PropsWithChildren<WalletModalProps>> = ({
  initialView = WalletView.WALLET_INFO,
  onDismiss = () => {},
}) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  // const { address: account } = useAccount()
  // const { data, isFetched } = useBalance({ address: account })
  const hasLowNativeBalance = false // Boolean(isFetched && data && data.value <= LOW_NATIVE_BALANCE)

  const handleClick = useCallback((newIndex: number) => {
    setView(newIndex)
  }, [])

  return (
    <ModalWrapper
      width={['100%', '', '', '460px']}
      mx="auto"
      borderRadius="16px"
      maxWidth={['100%', '', '', '460px']}
      minWidth="360px"
    >
      <ModalHeader borderBottomWidth="0 !important" background="transparent !important">
        <ModalTitle>
          <Heading>{t('Your Wallet')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="black" />
        </IconButton>
      </ModalHeader>

      {view !== WalletView.WRONG_NETWORK && <TabsComponent view={view} handleClick={handleClick} />}

      <ModalBody p="24px" width="100%">
        {view === WalletView.WALLET_INFO && (
          <WalletInfo hasLowNativeBalance={hasLowNativeBalance} switchView={handleClick} onDismiss={onDismiss} />
        )}
        {view === WalletView.TRANSACTIONS && <WalletTransactions onDismiss={onDismiss} />}
        {view === WalletView.CROSS_TRANSACTIONS && <WalletCrossTransactions onDismiss={onDismiss} />}
        {view === WalletView.WRONG_NETWORK && <WalletWrongNetwork onDismiss={onDismiss} />}
      </ModalBody>
    </ModalWrapper>
  )
}

export default WalletModal
