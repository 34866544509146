import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 99 99" {...props}>
      <path d="M49.5 99C62.6282 99 75.2187 93.7848 84.5018 84.5018C93.7848 75.2187 99 62.6282 99 49.5C99 36.3718 93.7848 23.7813 84.5018 14.4982C75.2187 5.21516 62.6282 0 49.5 0C36.3718 0 23.7813 5.21516 14.4982 14.4982C5.21516 23.7813 0 36.3718 0 49.5C0 62.6282 5.21516 75.2187 14.4982 84.5018C23.7813 93.7848 36.3718 99 49.5 99ZM71.3496 40.4121L46.5996 65.1621C44.782 66.9797 41.843 66.9797 40.0447 65.1621L27.6697 52.7871C25.8521 50.9695 25.8521 48.0305 27.6697 46.2322C29.4873 44.434 32.4264 44.4146 34.2246 46.2322L43.3125 55.3201L64.7754 33.8379C66.593 32.0203 69.532 32.0203 71.3303 33.8379C73.1285 35.6555 73.1479 38.5945 71.3303 40.3928L71.3496 40.4121Z" />
    </Svg>
  );
};

export default Icon;
