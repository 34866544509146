import { ChainId } from '@pancakeswap/chains'
import { ArrowDownIcon, Box, BoxProps, FailedIcon, Flex, FlexProps, LinkExternal, Text } from '@pancakeswap/uikit'
import CircleLoadingDots from 'components/Loader/CircleLoadingDots'
import { ChainLogo } from 'components/Logo/ChainLogo'
import { useCurrencyCross } from 'hooks/Tokens'
import React from 'react'
import { CrossChainStatus, CrossChainTransaction } from 'state/crossSwapTransactions/types'
import { styled } from 'styled-components'
import { getCrossExploreLink } from 'utils'
import { getZetaChainByAnotherChain } from 'utils/wagmi'

interface CrossTransactionRowProps {
  txn: CrossChainTransaction
}

const TxnInfo: React.FC<FlexProps & { isAborted?: boolean; chainId: ChainId; hash: string | undefined }> = ({
  isAborted,
  chainId,
  hash,
  ...props
}) => {
  return (
    <Box maxWidth="24px">
      {isAborted ? (
        <Flex flexDirection="column" {...props}>
          <FailedIcon width={24} height={24} />
          <Text mt="4px" fontSize="14px" color="text80">
            Failed
          </Text>
        </Flex>
      ) : (
        <Flex flexDirection="column" {...props}>
          <Box minWidth={24} minHeight={24}>
            <ChainLogo chainId={chainId} />
          </Box>
          {hash ? (
            <LinkExternal style={{ cursor: 'pointer' }} mt="8px" href={hash}>
              <Text small color="white">
                View
              </Text>
            </LinkExternal>
          ) : (
            <CircleLoadingDots width="20px" height="20px" mt="8px" />
          )}
        </Flex>
      )}
    </Box>
  )
}

const CrossTransactionRow: React.FC<React.PropsWithChildren<CrossTransactionRowProps & BoxProps>> = ({
  txn,
  ...props
}) => {
  const {
    status,
    inputChain,
    outputChain,
    inputCurrencyId,
    outputCurrencyId,
    destinationHash = '',
    destinationChain,
    zetachainHashOb = '',
    hash = '',
  } = txn

  const inputCurrency = useCurrencyCross(inputCurrencyId, inputChain)
  const outputCurrency = useCurrencyCross(outputCurrencyId, outputChain)

  const isOutputPending = !destinationHash || status === CrossChainStatus.PendingOutbound
  const isPendingRevert = status === CrossChainStatus.PendingRevert
  const isPending = status === CrossChainStatus.PendingInbound || isPendingRevert || isOutputPending

  const isAborted = status === CrossChainStatus.Aborted
  const isRevert =
    status === CrossChainStatus.Reverted || isPendingRevert || (destinationChain && destinationChain === inputChain)

  const statusName = isAborted
    ? 'Transaction Failed'
    : isRevert
    ? 'Transaction Revert'
    : isPending
    ? 'Transaction in progress'
    : 'Transaction successful'

  return (
    inputCurrency &&
    outputCurrency && (
      <CrossChainItem {...props}>
        <Flex justifyContent="space-between" position="relative">
          <TxnInfo
            chainId={inputChain}
            hash={inputChain && hash && getCrossExploreLink(inputChain, hash, true)}
            alignItems="flex-start"
          />
          <StyledDivider ml="6px" mr="8px" />

          <TxnInfo
            isAborted={isAborted}
            chainId={getZetaChainByAnotherChain(inputChain)}
            hash={
              inputChain &&
              zetachainHashOb &&
              getCrossExploreLink(getZetaChainByAnotherChain(inputChain), zetachainHashOb, false)
            }
            alignItems="center"
          />

          <StyledDivider mx="8px" />

          <TxnInfo
            isAborted={isAborted}
            chainId={destinationChain || outputChain}
            hash={destinationHash && destinationChain && getCrossExploreLink(destinationChain, destinationHash, true)}
            alignItems="flex-end"
          />
        </Flex>
        <Flex alignItems="center" position="relative" mt="8px">
          <Text fontSize="20px" color="white" bold>
            {inputCurrency.symbol}
          </Text>

          <ArrowDownIcon
            mx="12px"
            width="24px"
            color="white"
            style={{ transform: 'rotate(-90deg) translate(10%, -2%)' }}
          />

          <Text fontSize="20px" color="white" bold>
            {outputCurrency.symbol}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" mt="2px">
          <Text small color="text80">
            {statusName}
          </Text>

          {/* 
          {isPending && (
            <Flex alignItems="center">
              <IconButton width="max-content !important" ml="4px" variant="text" style={{ height: 'auto' }}>
                <Text small color="text80">
                  Refresh
                </Text>
                <AutoRenewIcon color="text80" style={{ transform: 'rotate(80deg)' }} />
              </IconButton>
            </Flex>
          )} */}
        </Flex>
      </CrossChainItem>
    )
  )
}

const CrossChainItem = styled(Box)`
  background: #323436;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
`

const StyledDivider = styled(Box)`
  position: relative;
  width: 100%;
  height: 24px;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    transform: scale(3);
    border-top: 1px dashed #fff;
  }
`

export default CrossTransactionRow
