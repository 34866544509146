import { ChainId, chainNames } from '@pancakeswap/chains'
import memoize from 'lodash/memoize'
import { defineChain } from 'viem'
import { Chain, bsc, bscTestnet, goerli, mainnet } from 'viem/chains'

export const zetachain = defineChain({
  id: 7000,
  name: 'ZetaChain',
  nativeCurrency: {
    decimals: 18,
    name: 'Zeta',
    symbol: 'ZETA',
  },
  rpcUrls: {
    default: {
      http: ['https://zetachain-evm.blockpi.network/v1/rpc/public'],
    },
  },
  blockExplorers: {
    default: {
      name: 'ZetaScan',
      url: 'https://explorer.zetachain.com',
    },
  },
  testnet: false,
})
export const zetachainAthensTestnet = defineChain({
  id: 7001,
  name: 'ZetaChain Athens Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Zeta',
    symbol: 'aZETA',
  },
  rpcUrls: {
    default: {
      http: ['https://zetachain-testnet-archive.allthatnode.com:8545/1Z8elL21s0HDJiYPTduQvpSXNP7mxj3x'],
    },
  },
  blockExplorers: {
    default: {
      name: 'ZetaScan',
      url: 'https://athens.explorer.zetachain.com',
    },
  },
  testnet: true,
})

export const DEFAULT_CHAIN = zetachain
export const DEFAULT_CHAINID = ChainId.MAINNET

export const CHAIN_QUERY_NAME = chainNames

const CHAIN_QUERY_NAME_TO_ID = Object.entries(CHAIN_QUERY_NAME).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName.toLowerCase()]: chainId as unknown as ChainId,
    ...acc,
  }
}, {} as Record<string, ChainId>)

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined
  return CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] ? +CHAIN_QUERY_NAME_TO_ID[chainName.toLowerCase()] : undefined
})

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS: ChainId[] = [
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.POLYGON_ZKEVM,
  ChainId.POLYGON_ZKEVM_TESTNET,
  ChainId.ZKSYNC,
  ChainId.ZKSYNC_TESTNET,
  ChainId.LINEA_TESTNET,
  ChainId.LINEA,
  ChainId.BASE,
  ChainId.BASE_TESTNET,
  ChainId.OPBNB,
  ChainId.OPBNB_TESTNET,
]

export const CHAINS: [Chain, ...Chain[]] = [
  zetachain,
  {
    ...zetachainAthensTestnet,
    blockExplorers: {
      default: {
        ...zetachainAthensTestnet.blockExplorers.default,
        url: 'https://athens.explorer.zetachain.com/',
      },
    },
  },
  bsc,
  mainnet,
  bscTestnet,
  goerli,
  // polygonZkEvm,
  // polygonZkEvmTestnet,
  // zkSync,
  // zkSyncTestnet,
  // arbitrum,
  // arbitrumGoerli,
  // linea,
  // lineaTestnet,
  // arbitrumGoerli,
  // arbitrum,
  // base,
  // baseGoerli,
  // opBNB,
  // opBNBTestnet,
  // scrollSepolia,
]

export const bitcoinMainnet = defineChain({
  id: ChainId.BTC_MAINNET,
  name: 'Bitcoin Mainnet',
  network: 'bitcoin',
  nativeCurrency: {
    decimals: 8,
    name: 'Bitcoin',
    symbol: 'BTC',
  },
  rpcUrls: {
    public: {
      http: [''],
    },
    default: {
      http: [''],
    },
  },
  blockExplorers: {
    default: {
      name: 'Bitcoin Explorer',
      url: 'https://live.blockcypher.com/btc',
    },
  },
})

export const bitcoinTestnet = defineChain({
  id: ChainId.BTC_TESTNET,
  name: 'Bitcoin Testnet',
  network: 'bitcoin',
  nativeCurrency: {
    decimals: 8,
    name: 'Bitcoin',
    symbol: 'BTC',
  },
  rpcUrls: {
    public: {
      http: [''],
    },
    default: {
      http: [''],
    },
  },
  blockExplorers: {
    default: {
      name: 'Bitcoin Explorer',
      url: 'https://live.blockcypher.com/btc-testnet',
    },
  },
  testnet: true,
})
export const CHAINS_BTC = [...CHAINS, bitcoinMainnet, bitcoinTestnet]
