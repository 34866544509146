export enum TokenForeignTypeEnums {
  Gas = 'Gas',
  ERC20 = 'ERC20',
}

export enum MethodCrossSwap {
  depositERC20 = 'depositERC20',

  crossChainZeta = 'crossChainZeta',

  crossChainSwap = 'crossChainSwap',

  crossChainSwapTransfer = 'crossChainSwapTransfer',
  crossChainSwapNative = 'crossChainSwapNative',
  crossChainSwapNativeTransfer = 'crossChainSwapNativeTransfer',

  crossChainSwapBTC = 'crossChainSwapBTC',
  crossChainSwapBTCTransfer = 'crossChainSwapBTCTransfer',
  crossChainSwapBTCTransferNative = 'crossChainSwapBTCTransferNative',
}

export enum ActionCrossChainSwapBTC {
  crossChainSwapBTC = '01',
  crossChainSwapBTCTransfer = '02',
  crossChainSwapBTCTransferNative = '03',
}

export enum ActionCrossChainSwap {
  crossChainSwap = '01',
  crossChainSwapTransfer = '02',
  crossChainSwapNativeTransfer = '03',
}

export const authenticatedBitcoin = 'bitcoin-authenticated'

export const MINIMUM_ZETA_TO_BTC = 10 // 10 zeta
export const MINIMUM_STATE_ZC20_TO_ERC20 = 5 // 5$;
export const MINIMUM_ERC20_TO_NATIVE = 10 // 10$;
