import { ChainId, isChainBitcoin } from '@pancakeswap/chains'
import { PUBLIC_NODES } from 'config/nodes'
import { getCosmosHttp } from 'contexts/ZetaContext/helpers/endpoints'
import { ethers } from 'ethers'
import { useCallback, useEffect } from 'react'
import { useCrossChainTransactionPendings, useUpdateTransactionCrossSwap } from './hooks'
import { CrossChainStatus, CrossChainTransaction } from './types'
import { mapperTxHash } from './utils'

const getInTxHashToCctxData = (hash: string, chainId: ChainId, transaction: CrossChainTransaction) => {
  const api = getCosmosHttp(chainId)

  try {
    return fetch(`${api}/zeta-chain/crosschain/in_tx_hash_to_cctx_data/${hash}`)
      .then(async (res) => {
        const data = await res.json()
        if (data?.CrossChainTxs?.[0]?.inbound_tx_params) {
          return mapperTxHash(data?.CrossChainTxs?.[0], transaction)
        }
        return null
      })
      .catch(() => {
        return null
      })
  } catch (e) {
    return null
  }
}

const getInTxHashToCctx = (hash: string, chainId: ChainId) => {
  const api = getCosmosHttp(chainId)

  try {
    return fetch(`${api}/zeta-chain/crosschain/inTxHashToCctx/${hash}`)
      .then(async (res) => {
        const data = await res.json()
        return data?.inTxHashToCctx
      })
      .catch(() => {
        return null
      })
  } catch (e) {
    return null
  }
}

const getCctxDetails = (hash: string, chainId: ChainId, transaction: CrossChainTransaction) => {
  const api = getCosmosHttp(chainId)

  try {
    return fetch(`${api}/zeta-chain/crosschain/cctx/${hash}`)
      .then(async (res) => {
        const data = await res.json()

        if (data?.CrossChainTx?.inbound_tx_params) {
          return mapperTxHash(data?.CrossChainTx, transaction)
        }
        return null
      })
      .catch(() => {
        return null
      })
  } catch (e) {
    return null
  }
}

enum TransactionStatus {
  Success = 1,
  Failed = 0,
}

const CrossChainTransactionUpdater = () => {
  const transactionPendings = useCrossChainTransactionPendings()
  const updateTransaction = useUpdateTransactionCrossSwap()

  const validateBirthHash = useCallback(
    async (txn: CrossChainTransaction) => {
      const rpc = PUBLIC_NODES?.[txn.inputChain]?.[0]
      if (!rpc) return false

      const status = await new ethers.providers.JsonRpcProvider(rpc)
        .getTransactionReceipt(txn.hash)
        .then((receipt) => receipt?.status)
        .catch(() => null)

      if (status === TransactionStatus.Failed) {
        updateTransaction({
          ...txn,
          status: CrossChainStatus.Aborted,
        })
        return false
      }
      return status === TransactionStatus.Success
    },
    [updateTransaction],
  )

  const listener = useCallback(
    async (txn: CrossChainTransaction) => {
      if (txn.status === CrossChainStatus.Inbound && !isChainBitcoin(txn.inputChain)) {
        const isAccept = await validateBirthHash(txn)
        if (!isAccept) return
      }

      let parseData = await getInTxHashToCctxData(txn.hash, txn.inputChain, txn)
      if (parseData?.zetachainHashOb) {
        const inTxHashToCctx = await getInTxHashToCctx(parseData.zetachainHashOb, parseData.inputChain)
        const obHash = inTxHashToCctx?.cctx_index?.[0] || parseData.zetachainHashOb
        if (obHash) {
          parseData = await getCctxDetails(obHash, parseData.inputChain, parseData)
        }
        if (parseData && JSON.stringify(parseData) !== JSON.stringify(txn)) {
          updateTransaction(parseData)
        }
      } else if (txn.status === CrossChainStatus.Inbound) {
        updateTransaction({
          ...txn,
          status: CrossChainStatus.PendingInbound,
        })
      }
    },
    [updateTransaction, validateBirthHash],
  )

  const callTxn = useCallback(() => {
    transactionPendings.forEach((txn: CrossChainTransaction) => {
      if (!txn) return
      try {
        listener(txn)
      } catch (error) {
        console.error(error)
      }
    })
  }, [listener, transactionPendings])

  useEffect(() => {
    let interval = 0

    if (transactionPendings.length === 0) {
      clearInterval(interval)
      return
    }

    callTxn()

    interval = setInterval(() => {
      if (transactionPendings.length === 0) {
        clearInterval(interval)
        return
      }
      callTxn()
    }, 60 * 1000)

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(interval)
    }
  }, [callTxn, listener, transactionPendings])

  return null
}
export default CrossChainTransactionUpdater
