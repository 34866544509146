import { Box, CircleLoadingDotsIcon } from '@pancakeswap/uikit'
import styled, { keyframes } from 'styled-components'

const CircleLoadingDots = ({ ...props }) => {
  return (
    <Loading {...props}>
      <CircleLoadingDotsIcon />
    </Loading>
  )
}

const animation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  `

const Loading = styled(Box)`
  display: flex;

  svg {
    animation: ${animation} 2.5s linear infinite;
  }
`
export default CircleLoadingDots
