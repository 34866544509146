import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg viewBox="0 0 16 16" {...props}>
    <path
      d="M8.383 11.4277C7.96222 11.4277 7.62109 11.7689 7.62109 12.1896V15.2373C7.62109 15.658 7.96222 15.9992 8.383 15.9992C8.80377 15.9992 9.1449 15.658 9.1449 15.2373V12.1896C9.1449 11.7689 8.80377 11.4277 8.383 11.4277Z"
      fill="#D1FAE5"
    />
    <path
      d="M8.383 0C7.96222 0 7.62109 0.34113 7.62109 0.761904V2.28571C7.62109 2.70649 7.96222 3.04762 8.383 3.04762C8.80377 3.04762 9.1449 2.70649 9.1449 2.28571V0.761904C9.1449 0.34113 8.80377 0 8.383 0Z"
      fill="#D1FAE5"
    />
    <path
      d="M4.57143 7.61933C4.57143 7.19855 4.2303 6.85742 3.80952 6.85742H0.761904C0.34113 6.85742 0 7.19855 0 7.61933C0 8.0401 0.34113 8.38123 0.761904 8.38123H3.80952C4.2303 8.38123 4.57143 8.0401 4.57143 7.61933Z"
      fill="#D1FAE5"
    />
    <path
      d="M16.0009 6.85742H12.9533C12.5325 6.85742 12.1914 7.19855 12.1914 7.61933C12.1914 8.0401 12.5325 8.38123 12.9533 8.38123H16.0009C16.4217 8.38123 16.7628 8.0401 16.7628 7.61933C16.7628 7.19855 16.4217 6.85742 16.0009 6.85742Z"
      fill="#D1FAE5"
    />
    <path
      d="M4.60851 10.313L2.45359 12.468C2.15609 12.7656 2.15609 13.248 2.45359 13.5455C2.60237 13.6943 2.79736 13.7686 2.99236 13.7686C3.18736 13.7686 3.38235 13.6943 3.53113 13.5455L5.6861 11.3905C5.9836 11.0929 5.9836 10.6106 5.68605 10.313C5.3885 10.0155 4.90611 10.0155 4.60851 10.313Z"
      fill="#D1FAE5"
    />
    <path
      d="M11.6135 5.14954C11.8085 5.14954 12.0035 5.07518 12.1522 4.92635L14.3072 2.77133C14.6047 2.47378 14.6047 1.99139 14.3072 1.69385C14.0097 1.39635 13.5272 1.3963 13.2297 1.69385L11.0747 3.84887C10.7772 4.14642 10.7772 4.6288 11.0747 4.92635C11.2235 5.07518 11.4185 5.14954 11.6135 5.14954Z"
      fill="#D1FAE5"
    />
    <path
      d="M12.1522 10.313C11.8548 10.0155 11.3723 10.0154 11.0747 10.3129C10.7772 10.6105 10.7772 11.0929 11.0747 11.3904L13.2297 13.5454C13.3785 13.6942 13.5735 13.7686 13.7685 13.7686C13.9634 13.7686 14.1585 13.6942 14.3072 13.5454C14.6047 13.2479 14.6047 12.7655 14.3072 12.468L12.1522 10.313Z"
      fill="#D1FAE5"
    />
  </Svg>
);

export default Icon;
