import { useContext, useMemo } from 'react'
import { ZetaContext } from './ZetaContext'

export const useAuthBitcoinContext = () => {
  const { bitcoinAccount, updateBitcoinAccount } = useContext(ZetaContext)
  return useMemo(() => ({ bitcoinAccount, updateBitcoinAccount }), [bitcoinAccount, updateBitcoinAccount])
}

export const useBitcoinAccount = () => {
  const { bitcoinAccount } = useContext(ZetaContext)
  return useMemo(() => bitcoinAccount, [bitcoinAccount])
}
export const useBitcoinLogout = () => {
  const { clearContext } = useContext(ZetaContext)
  return useMemo(() => clearContext, [clearContext])
}
