import { useTranslation } from '@pancakeswap/localization'
import { WNATIVE } from '@pancakeswap/sdk'
import {
  Box,
  Button,
  CopyAddress,
  Flex,
  FlexGap,
  InjectedModalProps,
  LinkExternal,
  Message,
  ScanLink,
  Skeleton,
  Text,
  useTooltip,
} from '@pancakeswap/uikit'
import { ChainLogo } from 'components/Logo/ChainLogo'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import useNativeCurrency from 'hooks/useNativeCurrency'
import useTokenBalance, { useZetaAbsBalance } from 'hooks/useTokenBalance'

import { formatBigInt, getFullDisplayBalance } from '@pancakeswap/utils/formatBalance'
import InternalLink from 'components/Links'
import { DEFAULT_CHAIN, DEFAULT_CHAINID } from 'config/chains'
import { SUPPORT_BUY_CRYPTO } from 'config/constants/supportChains'
import { useDomainNameForAddress } from 'hooks/useDomain'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { getBlockExploreLink, getBlockExploreName } from 'utils'
import { chainNameConverter } from 'utils/chainNameConverter'
import { useBalance } from 'wagmi'
// import CakeBenefitsCard from './CakeBenefitsCard'

const COLORS = {
  ETH: '#627EEA',
  BNB: '#14151A',
}

interface WalletInfoProps {
  hasLowNativeBalance: boolean
  switchView: (newIndex: number) => void
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({ hasLowNativeBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account, chainId, chain } = useActiveWeb3React()
  const { domainName } = useDomainNameForAddress(account)
  const isZeta = chainId === DEFAULT_CHAINID
  const zetaBalance = useBalance({
    address: account,
    chainId: DEFAULT_CHAINID,
  })
  const nativeBalance = useBalance({
    address: account,
    query: {
      enabled: !isZeta,
    },
  })

  const native = useNativeCurrency()
  const wNativeToken = !isZeta ? WNATIVE[chainId] : null
  const wZetaToken = WNATIVE[DEFAULT_CHAINID]
  const { balance: wNativeBalance, isFetched: wNativeFetched } = useTokenBalance(wNativeToken?.address)
  const { balance: wZETABalance, isFetched: wZETAFetched } = useTokenBalance(wZetaToken?.address, true)
  const { balance: absBalance, isFetched: absFetched, currency: absCurrency } = useZetaAbsBalance()
  const [mobileTooltipShow, setMobileTooltipShow] = useState(false)
  const { logout } = useAuth()

  const handleLogout = () => {
    onDismiss?.()
    logout()
  }
  const {
    tooltip: buyCryptoTooltip,
    tooltipVisible: buyCryptoTooltipVisible,
    targetRef: buyCryptoTargetRef,
  } = useTooltip(
    <>
      <Box maxWidth="140px">
        <FlexGap gap="8px" flexDirection="column" justifyContent="space-between">
          <Text as="p">
            {t('%currency% Balance Low. You need %currency% for transaction fees.', {
              currency: native?.symbol,
            })}
          </Text>
          <InternalLink href="/buy-crypto" onClick={() => onDismiss?.()}>
            <Button height="30px">{t('Buy %currency%', { currency: native?.symbol })}</Button>
          </InternalLink>
        </FlexGap>
      </Box>
    </>,
    {
      isInPortal: false,
      placement: isMobile ? 'top' : 'bottom',
      trigger: isMobile ? 'focus' : 'hover',
      ...(isMobile && { manualVisible: mobileTooltipShow }),
    },
  )

  const showBscEntryPoint = Number(zetaBalance?.data?.value) === 0 && SUPPORT_BUY_CRYPTO.includes(chainId)
  const showNativeEntryPoint = Number(nativeBalance?.data?.value) === 0 && SUPPORT_BUY_CRYPTO.includes(chainId)

  return (
    <>
      <Text color="black" fontSize="14px" mb="16px">
        {t('Your Address')}
      </Text>
      <FlexGap flexDirection="column" mb="24px" gap="8px">
        {account && <CopyAddress tooltipMessage={t('Copied')} account={account} />}
        {domainName ? <Text color="textSubtle">{domainName}</Text> : null}
      </FlexGap>
      {hasLowNativeBalance && SUPPORT_BUY_CRYPTO.includes(chainId) && (
        <Message variant="warning" mb="24px">
          <Box>
            <Text color="black" fontWeight="bold">
              {t('%currency% Balance Low', {
                currency: native.symbol,
              })}
            </Text>
            <InternalLink href="/buy-crypto" onClick={() => onDismiss?.()}>
              <Text color="primary">
                {t('You need %currency% for transaction fees.', {
                  currency: native.symbol,
                })}
              </Text>
            </InternalLink>
          </Box>
        </Message>
      )}
      {!isZeta && chain && (
        <Box mb="12px">
          <Flex justifyContent="space-between" alignItems="center" mb="8px">
            <Flex bg={COLORS.ETH} borderRadius="16px" pl="4px" pr="8px" py="2px">
              <ChainLogo chainId={chain.id} />
              <Text color="white" ml="4px">
                {chain.name}
              </Text>
            </Flex>
            <LinkExternal href={getBlockExploreLink(account, 'address', chainId)}>
              {getBlockExploreName(chainId)}
            </LinkExternal>
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="black">
              {native.symbol} {t('Balance')}
            </Text>
            {!nativeBalance.isFetched ? (
              <Skeleton height="22px" width="60px" />
            ) : (
              <Flex>
                <Text color={showNativeEntryPoint ? 'black' : 'black'}>
                  {formatBigInt(nativeBalance?.data?.value ?? 0n, 6)}
                </Text>
                {/* {showNativeEntryPoint ? (
                  <TooltipText
                    ref={buyCryptoTargetRef}
                    onClick={() => setMobileTooltipShow(false)}
                    display="flex"
                    style={{ justifyContent: 'center' }}
                  >
                    <InfoFilledIcon pl="2px" fill="#000" color="#D67E0A" width="22px" />
                  </TooltipText>
                ) : null}
                {buyCryptoTooltipVisible && (!isMobile || mobileTooltipShow) && buyCryptoTooltip} */}
              </Flex>
            )}
          </Flex>
          {wNativeBalance && wNativeBalance.gt(0) && (
            <Flex alignItems="center" justifyContent="space-between">
              <Text color="black">
                {wNativeToken?.symbol} {t('Balance')}
              </Text>
              {wNativeFetched && wNativeToken?.decimals ? (
                <Text color="black">{getFullDisplayBalance(wNativeBalance, wNativeToken?.decimals, 6)}</Text>
              ) : (
                <Skeleton height="22px" width="60px" />
              )}
            </Flex>
          )}
        </Box>
      )}

      <Box mb="24px">
        <Flex justifyContent="space-between" alignItems="center" mb="8px">
          <Flex bg={COLORS.BNB} borderRadius="16px" pl="4px" pr="8px" py="2px">
            <ChainLogo chainId={DEFAULT_CHAINID} />
            <Text color="white" ml="4px">
              {chainNameConverter(DEFAULT_CHAIN.name)}
            </Text>
          </Flex>
          <ScanLink href={getBlockExploreLink(account as string, 'address', DEFAULT_CHAINID)}>
            {getBlockExploreName(DEFAULT_CHAINID)}
          </ScanLink>
        </Flex>

        <Flex alignItems="center" justifyContent="space-between">
          <Text color="black">
            {zetaBalance?.data?.symbol} {t('Balance')}
          </Text>
          {!zetaBalance.isFetched ? (
            <Skeleton height="22px" width="60px" />
          ) : (
            <Flex alignItems="center" justifyContent="center">
              <Text fontWeight="normal" color="normal">
                {formatBigInt(zetaBalance?.data?.value ?? 0n, 6)}
              </Text>
              {/* {showBscEntryPoint ? (
                <TooltipText
                  ref={buyCryptoTargetRef}
                  onClick={() => setMobileTooltipShow(false)}
                  display="flex"
                  style={{ justifyContent: 'center' }}
                >
                  <InfoFilledIcon pl="2px" fill="#000" color="#D67E0A" width="22px" />
                </TooltipText>
              ) : null}
              {buyCryptoTooltipVisible && (!isMobile || mobileTooltipShow) && buyCryptoTooltip} */}
            </Flex>
          )}
        </Flex>
        {wZETABalance.gt(0) && (
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="black">WZETA {t('Balance')}</Text>
            {wZETAFetched ? (
              <Text>{getFullDisplayBalance(wZETABalance, wZetaToken.decimals, 6)}</Text>
            ) : (
              <Skeleton height="22px" width="60px" />
            )}
          </Flex>
        )}
        <Flex alignItems="center" justifyContent="space-between">
          <Text color="black">{t('ABS Balance')}</Text>
          {absFetched ? (
            <Text>{getFullDisplayBalance(absBalance, absCurrency.decimals, 6)}</Text>
          ) : (
            <Skeleton height="22px" width="60px" />
          )}
        </Flex>
      </Box>
      {/* <CakeBenefitsCard onDismiss={onDismiss} /> */}
      <Button variant="primary" width="100%" minHeight={48} onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
    </>
  )
}

export default WalletInfo
