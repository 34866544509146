import { useEffect } from 'react'
import { useConfig, useConnect } from 'wagmi'

const useEagerConnect = () => {
  const config = useConfig()
  const { connectAsync, connectors } = useConnect()
  useEffect(() => {
    if (
      !(typeof window === 'undefined') &&
      window?.parent !== window &&
      // @ts-ignore
      !window.cy
    ) {
      import('wagmi/connectors').then(({ safe }) => {
        connectAsync({
          connector: safe({
            shimDisconnect: true,
          }),
        }).catch(() => {})
      })
    }
  }, [config, connectAsync, connectors])
}

export default useEagerConnect
