import { authenticatedBitcoin } from 'config/constants/cross-swap'
import { useBitcoinAccount } from 'contexts/ZetaContext/hook'
import { useVariableLoaded } from 'hooks/useVariableLoaded'
import { useMemo } from 'react'
import { XFIWalletProviderProvider } from './connectors/XdefiWalet'

export enum WalletBitcoinConnectorEnums {
  XFI = 'XFI',
  UniSat = 'UniSat',
  Xverse = 'Xverse',
}

export const useXFI = () => {
  const xfi = useVariableLoaded(() => {
    if (typeof window !== 'object') return null

    const w = (window as any) || {}
    return w?.xfi && w?.xfi?.installed && w?.xfi?.bitcoin
  })

  return useMemo(() => (xfi ? (window as any)?.xfi?.bitcoin : null), [xfi])
}

export const useUnisat = () => {
  const unisat = useVariableLoaded(() => {
    if (typeof window !== 'object') return null

    const w = (window as any) || {}
    return w?.unisat && w?.unisat?._initialized
  })

  return useMemo(() => (unisat ? (window as any)?.unisat : null), [unisat])
}

export const useXverse = () => {
  const xverse = useVariableLoaded(() => {
    if (typeof window !== 'object') return null

    const w = (window as any) || {}
    return w?.XverseProviders?.BitcoinProvider
  })

  return useMemo(() => (xverse ? (window as any)?.XverseProviders?.BitcoinProvider : null), [xverse])
}

export const useWalletBitcoinProviders = () => {
  const xfi = useXFI()
  // const uniSat = useUnisat()
  // const xverse = useXverse()

  return useMemo(
    () => ({
      [WalletBitcoinConnectorEnums.XFI]: xfi ? new XFIWalletProviderProvider(xfi) : null,
      // [WalletBitcoinConnectorEnums.UniSat]: uniSat ? new UniSatWalletProviderProvider(uniSat) : null,
      // [WalletBitcoinConnectorEnums.Xverse]: xverse ? new XverseWalletProvider(xverse) : null,
    }),
    [xfi],
  )
}

export const useWalletBitcoinProviderByWallet = () => {
  const walletBitcoinProvider = useWalletBitcoinProviders()
  const bitcoinAccount = useBitcoinAccount()

  return useMemo(() => {
    const wallet = typeof window !== 'undefined' ? window.localStorage.getItem(authenticatedBitcoin) : undefined
    return wallet ? walletBitcoinProvider[wallet as WalletBitcoinConnectorEnums] : undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletBitcoinProvider, bitcoinAccount])
}
