import { bsc, bscTestnet, linea, zetachainAthensTestnet } from 'viem/chains'

export const chainNameConverter = (name: string) => {
  switch (name) {
    case bsc.name:
      return 'BNB Chain'
    case linea.name:
      return 'Linea'
    case zetachainAthensTestnet.name:
      return 'ZetaChain Athens'
    case bscTestnet.name:
      return 'BNB Chain Testnet'
    default:
      return name
  }
}
