import { ChainId } from '@pancakeswap/chains'
import { DEFAULT_CHAINID, getChainId } from 'config/chains'
import { atom, useAtomValue } from 'jotai'
import { useRouter } from 'next/router'
import { useDeferredValue, useMemo } from 'react'
import { isChainSupported } from 'utils/wagmi'
import { useAccount, useChainId } from 'wagmi'
import { useSessionChainId } from './useSessionChainId'

const queryChainIdAtom = atom(-1) // -1 unload, 0 no chainId on query

queryChainIdAtom.onMount = (set) => {
  const params = new URL(window.location.href).searchParams
  let chainId
  // chain has higher priority than chainId
  // keep chainId for backward compatible
  const c = params.get('chain')
  if (!c) {
    chainId = params.get('chainId')
  } else {
    chainId = getChainId(c)
  }
  if (isChainSupported(+chainId)) {
    set(+chainId)
  } else {
    set(0)
  }
}

export function useLocalNetworkChain() {
  const [sessionChainId] = useSessionChainId()
  // useRouter is kind of slow, we only get this query chainId once
  const queryChainId = useAtomValue(queryChainIdAtom)

  const { query } = useRouter()

  const chainId = +(sessionChainId || getChainId(query.chain as string) || queryChainId)

  if (isChainSupported(chainId)) {
    return chainId
  }

  return undefined
}

export const useActiveChainId = () => {
  const localChainId = useLocalNetworkChain()
  const queryChainId = useAtomValue(queryChainIdAtom)

  const { chain, chainId: walletChainId, address } = useAccount()
  const projectChainId = useChainId()

  const chainId = useMemo(
    () => localChainId ?? chain?.id ?? (queryChainId >= 0 ? DEFAULT_CHAINID : undefined),
    [localChainId, chain?.id, queryChainId],
  )

  const isNotMatched = useDeferredValue(
    address &&
      ((localChainId && chain?.id !== localChainId) ||
        (projectChainId && chain && chain?.id !== projectChainId) ||
        (chain && chain.id !== walletChainId) ||
        (walletChainId && projectChainId !== walletChainId)),
  )

  const unsupported = useMemo(() => (walletChainId && !chain) ?? false, [chain, walletChainId])

  return {
    chainId: chainId as ChainId,
    walletChainId,
    isWrongNetwork: Boolean(unsupported || isNotMatched),
    isNotMatched,
  }
}
