import { ChainId } from '@pancakeswap/chains'
import { isChainTestnet } from 'utils/wagmi'
import { BasePropsTransfer, BitcoinAccountWallet, RequestProps, WalletProvider } from './base'

enum BitcoinNetworkEnum {
  BITCOIN_TESTNET = 'testnet',
  BITCOIN_MAINNET = 'mainnet',
}
type IWalletProvider = {
  call: (...args) => void
  callBacksMap: any
  chainId: ChainId
  connect: (...args) => void
  getAccounts: () => Promise<any>
  network: BitcoinNetworkEnum
  requestAccounts: () => Promise<any>
  request: (args: RequestProps, callback?: (error: any, hash: any) => void) => any
  transfer: (args: BasePropsTransfer) => Promise<string>
  changeNetwork: (network: BitcoinNetworkEnum) => void
  signTransaction: (...args) => any
}

export class XFIWalletProviderProvider extends WalletProvider<IWalletProvider> {
  // eslint-disable-next-line class-methods-use-this
  public getNetwork = () => {}

  public connect = async () => {
    return this.getAccounts()
  }

  public getAccounts = async (): Promise<BitcoinAccountWallet[]> => {
    const accounts = await this.provider.getAccounts()

    return accounts.map(
      (account): BitcoinAccountWallet => ({
        address: account,
        publicKey: '',
      }),
    )
  }

  public getAccount = async (): Promise<BitcoinAccountWallet> => {
    const account = (await this.getAccounts())?.[0]
    return account
  }

  public changeNetwork = async (chainId: ChainId) => {
    return this.provider.changeNetwork(
      isChainTestnet(chainId) ? BitcoinNetworkEnum.BITCOIN_TESTNET : BitcoinNetworkEnum.BITCOIN_MAINNET,
    )
  }

  public request = (data: RequestProps, callback?: (error: any, hash: any) => void) => {
    return this.provider.request(data, callback)
  }

  public transfer = ({ feeRate, from, recipient, amount, decimals, memo }: BasePropsTransfer): Promise<string> => {
    return new Promise((resolve, reject) =>
      this.request(
        {
          method: 'transfer',
          params: [
            {
              feeRate,
              from,
              recipient,
              amount: {
                amount,
                decimals,
              },
              memo,
            },
          ],
        },
        (error, hash: string) => {
          if (error) {
            reject(error)
          } else {
            resolve(hash)
          }
        },
      ),
    )
  }
}
