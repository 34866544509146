import { Flex, FlexGap, OpenNewIcon, Text, UserMenuItem } from '@pancakeswap/uikit'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { ChainLogo } from 'components/Logo/ChainLogo'
import { useBitcoinAccount } from 'contexts/ZetaContext/hook'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getBlockExploreLink } from 'utils'
import { getBTCChainByAnotherChain, getZetaChainByAnotherChain } from 'utils/wagmi'

const UserAccount = () => {
  const { account, chainId } = useActiveWeb3React()

  const bitcoinAddress = useBitcoinAccount()

  const bitcoinChainId = getBTCChainByAnotherChain(chainId)
  const zetaChainId = getZetaChainByAnotherChain(chainId)

  return (
    account && (
      <>
        <Flex flexDirection="column" pt="8px">
          <Text px="16px" color="text80" small bold>
            Account
          </Text>

          <FlexGap flexDirection="column">
            <UserMenuItem as="a" target="_" href={getBlockExploreLink(account, 'address', zetaChainId)}>
              <Flex>
                <ChainLogo chainId={zetaChainId} />
                <Text color="text80" small letterSpacing={1} ml="8px">
                  {account && truncateHash(account, 4, 6)}
                </Text>
              </Flex>

              <OpenNewIcon />
            </UserMenuItem>
          </FlexGap>

          {bitcoinAddress && (
            <UserMenuItem as="a" target="_" href={getBlockExploreLink(bitcoinAddress, 'address', bitcoinChainId)}>
              <Flex>
                <ChainLogo chainId={bitcoinChainId} />
                <Text color="text80" small letterSpacing={1} ml="8px">
                  {truncateHash(bitcoinAddress, 4, 6)}
                </Text>
              </Flex>
              <OpenNewIcon />
            </UserMenuItem>
          )}
        </Flex>
      </>
    )
  )
}

export default UserAccount
